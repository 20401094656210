import React from "react";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Header from "root/sections/Header";
import Section from "root/components/Section";
import Typography from "root/components/Typography";
import Footer from "root/sections/Footer";

import mdxStyles from "root/styles/mdx.module.css";
import Privacy, { _frontmatter } from "../../data/documents/privacyPolicy.mdx";
import styles from "./privacy.module.css";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <SEO title="Drivit | Privacy Policy" />
      <Layout>
        <Header currentPage="privacy" background="white" />

        <Section>
          <div className={styles.title}>
            <Typography variant="h1" color="green" weight="bold">
              {_frontmatter.title}
            </Typography>
          </div>
          <div className={styles.root}>
            <div className={mdxStyles.content}>
              <Privacy />
            </div>
          </div>
        </Section>
        <Footer backgroundColor="lightGrey" />
      </Layout>
    </div>
  );
};

export default PrivacyPolicyPage;
