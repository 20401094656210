import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Privacy Policy"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We work tirelessly to bring our users products and services that are meant to be beautifully designed, intuitive and truly useful. In doing so, we collect information from or about our users without which our products and services would be flawed or non-existent at all.`}</p>
    <p>{`We are aware of the great responsibility of holding such information and therefore take a serious stance to protect it along with our users’ privacy. We invite you to learn more about exactly how we play our role by reading this Privacy Policy. Should you have any questions regarding this policy, please don’t hesitate to e-mail us at `}<a parentName="p" {...{
        "href": "mailto:privacy@drivit.com",
        "target": "_blank",
        "rel": "noopener"
      }}>{`privacy@drivit.com`}</a>{`.`}</p>
    <h2>{`1. The controller of personal data property`}</h2>
    <p>{`“Bahub Business Analysis Systems, Lda.” (hereinafter referred to as `}<strong parentName="p">{`Bahub`}</strong>{`) is the controller of personal data processing, which we gather when you are accessing or using `}<strong parentName="p"><em parentName="strong">{`Drivit`}</em></strong>{` or any other `}<strong parentName="p">{`Bahub`}</strong>{` product or service.Our contact details are as follows:`}</p>
    <p>{`Address: Bahub Business Analysis Systems, Lda. (`}<strong parentName="p">{`Bahub`}</strong>{`), Avenida António Augusto de Aguiar 24, 2.º Esquerdo, 1050-016 Lisboa`}</p>
    <p>{`Email: `}<a parentName="p" {...{
        "href": "mailto:privacy@drivit.com",
        "target": "_blank",
        "rel": "noopener"
      }}>{`privacy@drivit.com`}</a></p>
    <p>{`The easiest way to reach our data protection officer is by sending an email to `}<a parentName="p" {...{
        "href": "mailto:privacy@drivit.com",
        "target": "_blank",
        "rel": "noopener"
      }}>{`privacy@drivit.com`}</a>{` or writing us at the address above.`}</p>
    <h2>{`2. Personal data covered by this Privacy Policy`}</h2>
    <p><strong parentName="p"><em parentName="strong">{`Drivit`}</em></strong>{` is enabled by our apps, which can be complemented by the information we collect using: i) a hardware device which plugs into your vehicle (the “OBD adapter”), if you buy one, install it into your car and connect it to one of the Drivit apps, ii) our website. When using Drivit, you provide us with and we collect the following types of personal data:`}</p>
    <p>{`a. Personally identifiable information`}</p>
    <p>{`b. Facebook and Google login information`}</p>
    <p>{`c. Information from your vehicle’s onboard computer`}</p>
    <p>{`d. Information from your smartphone, including location information`}</p>
    <p>{`e. Information about the characteristics of your vehicle;`}</p>
    <p>{`f. Information from our website.`}</p>
    <h3>{`a. Personally identifiable information`}</h3>
    <p>{`Through your registration process and/or through your account settings, we collect personally identifiable information such as your name/surname and/or e-mail address. We may also collect your car license plate number or vehicle identification number to improve your experience when you use our services, particularly your registering process by automatically providing all the technical specifications of your car. You may also choose to identify some of your common location places (e.g. home, work), which enables us to simplify the display of your common addresses and to give you a summary of relevant information for specific groups of trips (e.g. total monthly cost of the home to work trips).`}</p>
    <p>{`We may communicate with you through the means you’ve provided, e.g. email, with the main purpose of offering the best customer service and support, and also to keep you updated with important changes and updates to the service. Nevertheless, if at any time you decide that you do not want to receive communications from us, please indicate your preference by unsubscribing from such messages through the “Unsubscribe” link at the bottom of such e-mails. We may also send you alerts through your mobile device if your settings on your mobile device operating system permit such alerts. If you do not wish to receive alerts from us, you have the option to disable them in your settings on your mobile device.`}</p>
    <h3>{`b. Facebook and Google login information`}</h3>
    <p>{`We may give you the option for registering for or logging into the Drivit mobile apps through an external authentication provider (the “Authentication provider”) such as Facebook or Google, using their account login systems. For registering with us using the available `}<strong parentName="p">{`Authentication provider`}</strong>{` in Drivit apps, you will first be redirected to the respective authenticator page where you will be prompted to i) insert your account details, if you are not already logged into the `}<strong parentName="p">{`Authentication provider`}</strong>{`; ii) verify and approve the permissions you may give us by logging with this provider in our apps or services. This link will allow us to view some of the data that you have provided to specific `}<strong parentName="p">{`Authentication provider`}</strong>{` (e.g. account id, name/surname, email address, profile picture) and will be used in accordance to Article 6(1.)(f) of the GDPR. You can revoke these permissions at any time in your `}<strong parentName="p">{`Authentication provider`}</strong>{` account settings by removing our permissions.`}</p>
    <h3>{`c. Information from your vehicle’s onboard computer`}</h3>
    <p>{`To enable some of our Services, e.g. real-time display of vehicle data, we may collect and store information from the vehicle into which you plug a compatible `}<strong parentName="p">{`OBD adapter`}</strong>{`. Examples of the type of information we collect from the vehicle’s onboard computer and sensors include: fuel consumption data, which we present along with the logged trips; and diagnostic trouble codes, which help explain why the vehicle’s check engine light came on.`}</p>
    <h3>{`d. Information from your smartphone, including location information`}</h3>
    <p>{`Examples of the type of information we collect from your smartphone include: location and acceleration data from your smartphone sensors. We use your phone location information to provide you with more information regarding your logged trips. Nevertheless, the collection of this data is solely at your discretion and you can enable or disable location services when you use the App at any time, through your mobile device settings. We could also have access to other identifiable information like your IP address, your smartphone identification code, and the operating system identification code, which we use for bug identification and fixing, security purposes, systems improvements, continuous maintenance and service resilience.`}</p>
    <h3>{`e. Information about the specifications of your vehicle`}</h3>
    <p>{`The specification of your vehicle includes details such as the brand, model and manufacturing year of your car. This information is obtained through our app/website, and it may be complemented using third-party services. We collect this information with the main purpose of improving your experience with Drivit and enable the continuous development of additional features.`}</p>
    <h3>{`f. Information from our website`}</h3>
    <p>{`When you visit our website, we use a common technology called “cookies” to automatically collect information about your use and the equipment that you use to access our site. Cookies are pieces of information that websites send to your computer or other Internet-connected devices to uniquely identify your browser or to store information or settings on your device. Cookies may also be placed by third-party analytics services that we use, such as Google Analytics.`}</p>
    <p>{`You can choose to opt-out of this by using the “Do Not Track” feature of your browser. Please note, however, that without cookies you may not be able to use all of the features of our site or other websites and online services.`}</p>
    <h2>{`3. Purposes of the processing of the acquired data`}</h2>
    <p>{`The purposes of processing your data include: i) create your user account; ii) show you your log of trips and present how several key indicators have been evolving over different perspectives (e.g. time, type of trip, trip origin/destination); iii) complement your car’s dashboard by providing real time information of several of your car’s sensors; iv) allow you to do an initial diagnosis of potential problems that your car might have registered; v) search for fuel stations and corresponding fuel prices for a given location area; vi) improve the operation of Drivit and/or develop additional meaningful features and services for your use.`}</p>
    <p>{`In principle, we process and store each type of personal data solely during the period for which it is necessary for the purposes defined above and to comply with our contractual obligation to you, or if it is necessary for lawful or quality assurance processes. Notwithstanding, apart from i) special statutory provisions; ii) your account registration information (e.g. name, car, email) when your account is still active; we’ve introduced a default personal data retention period of 5 years so that your data isn’t held longer than is necessary.`}</p>
    <h2>{`4. Access and transmission of personal data`}</h2>
    <p><strong parentName="p">{`Bahub`}</strong>{` employees have supervised access to your information so that we can provide Services to you and help you if you contact us for support. Regarding third parties, rest assured that `}<strong parentName="p">{`we do not share`}</strong>{` your Personal Data with them without your explicit permission, except in the situations provided below:`}</p>
    <ul>
      <li parentName="ul">{`Trusted third parties performing business-related functions: We sometimes work with other companies to perform certain business-related functions such as processing payments, maintaining our systems or further developing our products and services. Where this occurs, we take the appropriate precautions in order to ensure that parties are provided only with the information they specifically need and are reputable and offer the highest guarantees at this level, which shall be duly established and safeguarded contractually;`}</li>
      <li parentName="ul">{`Legal process: We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is necessary to comply with law or court order; enforce or apply our Terms of Use and other agreements; or protect the rights, property, or safety of `}<strong parentName="li">{`Bahub`}</strong>{`, our employees, our users, or others;`}</li>
      <li parentName="ul">{`Anonymized or aggregated data: We may share data with our affiliates, business partners or users, that does not explicitly identify you or your vehicle (e.g. average fuel consumption in a given route). We may also disclose aggregated user statistics in order to describe Drivit to current and prospective business partners and to third parties for other lawful purposes.`}</li>
      <li parentName="ul">{`Data storage: we store all our data with a cloud service provider within the EU or IT infrastructures and systems at our sites within the EU. We can assure you that we make our best effort to ensure the implementation of proper measures, including the industry’s standard in terms of data security, that make your personal data secure at all times, both at transit and at rest.`}</li>
      <li parentName="ul">{`Business Transfers: We may choose to buy or sell assets. In these types of transactions, customer information is typically one of the business assets that would be transferred. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, Personal Information could be one of the assets transferred to or acquired by a third party.`}</li>
    </ul>
    <p>{`To provide you with an enhanced user experience, you may choose to share your information with third parties through our App. In these circumstances, we will not share your Personal Data without your explicit permission, usually in the form of a web page, email or app screen where you authorize such sharing of information.`}</p>
    <p>{`The implementation and provision of certain services may need the transfer of personal data abroad. However, in these cases, legal provisions will be observed, namely provisions assuring that the same level (or higher) of protection of your personal data is followed by the external service providers in these countries.`}</p>
    <p>{`We certify that our external service providers process your information in a strictly confidential manner and following scrupulously the applicable law and data protection regulations, including the GDPR.`}</p>
    <p>{`We do not, and never intend to, sell any of your personal data to any third-party.`}</p>
    <h2>{`5. The data subject’s rights`}</h2>
    <p>{`As a user of Drivit, and under the applicable laws, you have a series of rights that allow you to decide and control at any time what personal data we collect and how we use it. These rights can be exercised at any time and for free, unless they are used in an abusive or unreasonable manner (e.g. multiple right exercise requests when a right has already been exercised before and no material changes have happened since). To exercise your rights, you can do it using the functionalities provided in our account settings or, when not possible, through contacting us via the email `}<a parentName="p" {...{
        "href": "mailto:privacy@drivit.com",
        "target": "_blank",
        "rel": "noopener"
      }}>{`privacy@drivit.com`}</a>{`. In order for us to identify you properly, you should facilitate a copy of an identification document and explicitly state the right you wish to exercise.`}</p>
    <p>{`In particular, you have the following rights:`}</p>
    <p>{`a. Right to access – you can request that we confirm if we are or not using your personal data; if we are indeed using them, we will also indicate which personal data we have about you, to which purposes we are using them, the entities to whom we are sharing them, the period for which your data will be stored, if we are using them for profiling or automatic decision making, among others.`}</p>
    <p>{`b. Right to rectification – You can ask us to change some of your personal data for them to be accurate and up-to-date. In fact, we strongly advise keeping your personal data updated. Shall you have any issues updating your data, do not hesitate to contact us and we will promptly update it.`}</p>
    <p>{`c. Right to erasure (“right to be forgotten”) – This right will automatically be exercised if the personal data in question is no longer needed for the purpose that we stated that we collected it for, or in the case when you explicitly ask us for your personal data to be deleted.`}</p>
    <p>{`d. Right to restrict processing – You can request a temporary restriction on the usage of your personal data (you should be advised that, meanwhile, we may not be able to provide you our services in full) i) if you consider your data is inaccurate, and until it is verified or update, ii) if your personal data is no longer used for the intent it has collected for, but you wish to keep it to sustain any complaints and or defend yourself, iii) if you oppose the use of your personal data for a specific purpose and we evaluate the matter.`}</p>
    <p>{`e. Right to data portability – You can ask us to deliver you, or to a specified authority, whenever technically possible and reasonable, a structured file of common use with a readable content by means of the use of a safe and secure IT system. The file will include personal data like trips/location data, personally identifiable information that we have collected in the scope of our service.`}</p>
    <p>{`f. Right to complain – If you believe that any of your rights are not being fully met it is of our greatest interest to correct this situation without undue and to report it to the competent authorities, for that purpose you can contact us at privacy@drivit.com. It is also your right to lodge a complaint to the competent supervisory authority.`}</p>
    <p>{`g. Right to object – You can oppose the processing of your personal data for any of the purposes defined in clause 2 or if you do not agree with any of the points in clause 4.`}</p>
    <h2>{`6. Protection of Personal Information`}</h2>
    <p>{`We have taken appropriate technical and organizational measures to guarantee data security, in particular, to protect your personal data against access by third parties, as well as accidental or intentional modification, loss or destruction. Such measures are reviewed periodically and adapted in line with the state of the art. The transfer of your personal data from your terminal equipment (e.g. smartphone) to us is always encrypted.`}</p>
    <p>{`Your account is protected by a password for your privacy and security. If you access your account via a third-party site or service, you may have additional or different sign-on protections via that third-party site or service. You must prevent unauthorized access to your account and Personal Information by selecting and protecting your password and/or other sign-on mechanisms appropriately and limiting access to your computer or device. In case you connect Drivit to your car, we make use of a compatible `}<strong parentName="p">{`OBD adapter`}</strong>{` of your choice. This flexibility means that you can choose the most suitable adapter based on your needs (e.g. price, connection protocol, standby’s current consumption). Nonetheless, as the choice of a security compliant device is out of our reach, we cannot guarantee the security of the connection between the vehicle ECU and the smartphone via the `}<strong parentName="p">{`OBD adapter`}</strong>{`. Therefore, we deeply encourage you to choose a device which offers a secure, password protected connection with your smartphone.`}</p>
    <h2>{`7. Changes to this Privacy Policy`}</h2>
    <p>{`We are constantly trying to improve our services, so we may need to change this Privacy Policy from time to time as well. However, we will always alert you to changes by publishing a notice through the App or on your profile page, by sending you an e-mail, and/or by some other reasonable means.`}</p>
    <p>{`Last Updated: 24-05-2018`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      